import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import css from "../Modulecss/Home.module.css";

// Avatar image URLs
const avatarUrls = Array.from({ length: 36 }, (_, index) => 
  `${process.env.PUBLIC_URL}/Images/avatars/Avatar${index + 1}.png`
);

const fallbackAvatar = `${process.env.PUBLIC_URL}/Images/avatars/fallback.png`;

// Pre-load avatar images
const preloadAvatars = (urls) => {
  urls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

export default function RunningCard({ runnig, user, winnAmount, game_type }) {
  const [creatorAvatar, setCreatorAvatar] = useState(fallbackAvatar);
  const [acceptorAvatar, setAcceptorAvatar] = useState(fallbackAvatar);
  const [areAvatarsLoaded, setAreAvatarsLoaded] = useState(false); // Track avatar loading

  useEffect(() => {
    // Preload all avatars on component mount
    preloadAvatars(avatarUrls);

    // Set random avatars from avatarUrls
    const creatorRandomAvatar = avatarUrls[Math.floor(Math.random() * avatarUrls.length)];
    const acceptorRandomAvatar = avatarUrls[Math.floor(Math.random() * avatarUrls.length)];

    // Set avatars and mark them as loaded
    setCreatorAvatar(creatorRandomAvatar);
    setAcceptorAvatar(acceptorRandomAvatar);
    setAreAvatarsLoaded(true);
  }, []);

  return (
    <div className={`${css.betCard} mt-1`}>
      <div className="d-flex">
        <span className={`${css.betCardTitle} pl-3 d-flex align-items-center text-uppercase`}>
          PLAYING FOR
          <img className="mx-1" src="https://i.postimg.cc/D0nPWJnJ/global-rupee-Icon.png" alt="Rupee Icon" width="21px" />
          {runnig.Game_Ammount}
        </span>
        {(user === runnig?.Accepetd_By?._id || user === runnig?.Created_by?._id) && (
          <Link
            className={`${css.bgSecondary} ${css.playButton} ${css.cxy} position-relative m-2 mx-1 ${runnig.Status === "conflict" ? "bg-danger" : "bg-success"}`}
            style={{ right: "0px", top: "-6px", padding: "10px 17px" }}
            to={{
              pathname: `/viewgame1/${runnig._id}`,
              state: { prevPath: window.location.pathname }
            }}
          >
            View
          </Link>
        )}
        <div className={`${css.betCardTitle} d-flex align-items-center text-uppercase`}>
          <span className="ml-auto mr-3">
            PRIZE
            <img className="mx-1" src="https://i.postimg.cc/D0nPWJnJ/global-rupee-Icon.png" alt="Rupee Icon" width="21px" />
            {runnig.Game_Ammount + winnAmount(runnig.Game_Ammount)}
          </span>
        </div>
      </div>
      <div className="py-1 row">
        <div className="text-center col-5">
          <div className="d-flex flex-column align-items-center">
            {areAvatarsLoaded ? (
              <img
                src={creatorAvatar}
                onError={(e) => (e.target.src = fallbackAvatar)} // Fallback if image fails to load
                alt="Creator Avatar"
                width="50px"
                height="50px"
                style={{ borderRadius: "50%", marginBottom: "5px" }}
              />
            ) : (
              <div className="placeholderAvatar"></div> // Placeholder while loading
            )}
            <span className={`${css.betCard_playerName} mt-1`} style={{ fontSize: "1rem", fontWeight: "bold", color: "#333" }}>
              {runnig?.Created_by?.Name}
            </span>
          </div>
        </div>
        <div className="text-center col-2 cxy">
          <img src="https://i.postimg.cc/HYkL1Y2Z/versus.png" alt="Versus Icon" width="41px" />
        </div>
        <div className="text-center col-5">
          <div className="d-flex flex-column align-items-center">
            {areAvatarsLoaded ? (
              <img
                src={acceptorAvatar}
                onError={(e) => (e.target.src = fallbackAvatar)} // Fallback if image fails to load
                alt="Acceptor Avatar"
                width="50px"
                height="50px"
                style={{ borderRadius: "50%", marginBottom: "5px" }}
              />
            ) : (
              <div className="placeholderAvatar"></div> // Placeholder while loading
            )}
            <span className={`${css.betCard_playerName} mt-1`} style={{ fontSize: "1rem", fontWeight: "bold", color: "#333" }}>
              {runnig?.Accepetd_By?.Name}
            </span>
          </div>
        </div>
      </div>

      <style jsx>{`
        .placeholderAvatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #ddd; // Light grey color
        }
      `}</style>
    </div>
  );
}
