import React, { useState, useEffect } from 'react';

const Rightcontainer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    try {
      const response = await fetch(baseUrl + "settings/data");
      const data = await response.json();
      setWebsiteSettings(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [baseUrl]);

  useEffect(() => {
    document.title = WebSitesettings ? WebSitesettings.WebTitle : '';
  }, [WebSitesettings]);

  return (
    <div>
      <div className='rightContainer'>
        <div className="rcBanner flex-center ">
          <picture className="rcBanner-img-container animate__bounce infinite ">
            <img src="/Images/LandingPage_img/Header_profile.jpg" alt="nothing" />
          </picture>
          <div className="rcBanner-text" style={{ fontWeight: 'bolder' }}>
            {WebSitesettings ? WebSitesettings.WebsiteName : ''} <span className="rcBanner-text-bold" style={{ fontWeight: 'normal' }}>Win Real Cash</span>
          </div>
          <div className="rcBanner-footer">For best experience, open&nbsp;
            <a href="/" style={{ color: 'rgb(44, 44, 44)', fontWeight: 500, textDecoration: 'none' }}>
              {WebSitesettings ? WebSitesettings.WebsiteName : ''}
            </a>&nbsp;on&nbsp;
            <img src={process.env.PUBLIC_URL + '/Images/chrome.png'} alt="" />&nbsp;chrome mobile
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rightcontainer;