// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global Styles */
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.App {
    color: #228b22;
    text-align: center;
}

/* Footer Styles */
.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    color: white;
    font-size: 25px;
}

.my-sticky-footer {
    text-align: center;
    background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important;
    color: #fff;
    padding: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

/* Modal Styles (commented out)
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(64, 64, 64, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    width: 40%;
    padding: 10px 20px 10px 20px;
}

.modal-button {
    border-radius: 50%;
    background-color: rgb(93, 133, 93);
    color: white;
    padding: 5px 10px;
    cursor: pointer;
}
*/
`, "",{"version":3,"sources":["webpack://./src/app2.js/Components/Component-css/Downloadbutton.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,SAAS;IACT,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA,kBAAkB;AAClB;IACI,eAAe;IACf,WAAW;IACX,SAAS;IACT,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,iFAAiF;IACjF,WAAW;IACX,aAAa;IACb,eAAe;IACf,SAAS;IACT,WAAW;AACf;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;CA+BC","sourcesContent":["/* Global Styles */\nbody {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n}\n\n.App {\n    color: #228b22;\n    text-align: center;\n}\n\n/* Footer Styles */\n.footer {\n    position: fixed;\n    width: 100%;\n    bottom: 0;\n    color: white;\n    font-size: 25px;\n}\n\n.my-sticky-footer {\n    text-align: center;\n    background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important;\n    color: #fff;\n    padding: 20px;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n}\n\n/* Modal Styles (commented out)\n.modal {\n    position: fixed;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(64, 64, 64, 0.95);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.modal-content {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    background-color: white;\n    border-radius: 10px;\n    width: 40%;\n    padding: 10px 20px 10px 20px;\n}\n\n.modal-button {\n    border-radius: 50%;\n    background-color: rgb(93, 133, 93);\n    color: white;\n    padding: 5px 10px;\n    cursor: pointer;\n}\n*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
