import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";

const w3_close = () => {
  const width = document.getElementById("mySidebar").offsetWidth;
  document.getElementById("mySidebar").style.left = `-${width}px`;
  document.getElementById('sidebarOverlay').style.display = 'none';
};

const w3_open = () => {
  document.getElementById("mySidebar").style.left = "0";
  document.getElementById('sidebarOverlay').style.display = 'block';
};

const Header = ({ user, loggedIn }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const history = useHistory();
  let access_token = localStorage.getItem("token");

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [rotate, setRotate] = useState(false); // State to handle rotation
  const isMounted = useRef(true); // Track if component is mounted
  const timeoutId = useRef(null); // Track the timeout ID

  useEffect(() => {
    const source = axios.CancelToken.source(); // Create a cancel token

    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + "settings/data", {
          cancelToken: source.token // Pass the cancel token to the axios request
        });
        if (isMounted.current) {
          setWebsiteSettings(response.data);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Fetch canceled');
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false; // Cleanup function sets the flag to false
      source.cancel(); // Cancel the axios request if the component unmounts
      if (timeoutId.current) {
        clearTimeout(timeoutId.current); // Clear the timeout if the component unmounts
      }
    };
  }, [baseUrl]);

  const logout = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    axios.post(baseUrl + `logout`, {}, { headers })
      .then((res) => {
        localStorage.removeItem("token");
        window.location.reload();
        history.push('/');
      }).catch((e) => {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem('token');
        } else {
          console.error('Logout error:', e);
        }
      });
  };

  const handleLogoClick = () => {
    setRotate(true);
    timeoutId.current = setTimeout(() => {
      if (isMounted.current) {
        setRotate(false);
      }
    }, 1000); // Reset rotation state after 1 second
  };

  return (
    <div>
      <style>{`
        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .logo.rotate {
          animation: rotate 1s linear;
        }
      `}</style>

      {access_token ? (
        <React.Fragment>
          <div id='sidebarOverlay' onClick={w3_close}></div>
          <div className="w3-sidebar w3-bar-block" id="mySidebar" style={{ paddingBottom: '70px' }}>
            <Link to={"/Profile"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                {user && user.avatar ? (
                  <img width="30px" height="30px" src={baseUrl + `${user && user.avatar}`} alt="profile" style={{ borderRadius: "50px" }} />
                ) : (
                  <img src={baseUrl + WebSitesettings.SmallLogo} width="25px" height="25px" alt="profile" />
                )}
              </picture>
              <div style={{ marginLeft: '.5rem' }}>My Profile</div>
            </Link>
            <Link to={"/landing"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/gamepad.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Win cash</div>
            </Link>
            <Link to={"/wallet"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/wallet.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>My wallet</div>
            </Link>
            <Link to={"/Gamehistory"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/gamesHistory.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Game History</div>
            </Link>
            <Link to="/transaction-history" className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/order-history.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Transaction History</div>
            </Link>
            <Link to={"/refer"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/sreferEarn.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Refer and Earn</div>
            </Link>
            <Link to={"/Referral-history"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/refer-history.webp'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Refer History</div>
            </Link>
            <Link to={"/Notification"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/notifications.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Notification</div>
            </Link>
            <Link to={"/support"} className="w3-bar-item w3-button" onClick={w3_close}>
              <picture className="icon">
                <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/support.png'} />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Support</div>
            </Link>
          </div>
          <div className="w3-teal">
            <div className="w3-container">
              <div className={`${css.headerContainer} `}>
                <button
                  className="w3-button w3-teal w3-xlarge float-left"
                  onClick={w3_open}
                  id="hambergar"
                >
                  <picture className={`${css.sideNavIcon} mr-0`}>
                    <img
                      src='/Images/LandingPage_img/sidebar.png'
                      className="snip-img"
                      alt=''
                    />
                  </picture>
                </button>
                <Link to="/" >
                  <picture className={`ml-2 ${css.navLogo} d-flex`}>
                    <img
                      src='https://i.postimg.cc/7YmjkDQ2/Header-profile-2.png'
                      className={`snip-img logo ${rotate ? 'rotate' : ''}`} // Add rotate class based on state
                      alt='Logo'
                      onClick={handleLogoClick} // Handle click event
                    />
                  </picture>
                </Link>
                <div>
                  <div className={`${css.menu_items}`}>
                    <Link className={`${css.box}`} to="/Addcase">
                      <picture className={`${css.moneyIcon_container}`}>
                        <img
                          src={"https://i.postimg.cc/D0nPWJnJ/global-rupee-Icon.png"}
                          className="snip-img"
                          alt=''
                        />
                      </picture>
                      <div className="mt-1 ml-1">
                        <div className={`${css.moneyBox_header}`}>Cash</div>
                        <div className={`${css.moneyBox_text}`}>{user && user.Wallet_balance}</div>
                      </div>
                      <picture className={`${css.moneyBox_add}`}>
                        <img
                          src={'https://i.postimg.cc/TYBNPVC7/addSign.png'}
                          className="snip-img"
                          alt=''
                        />
                      </picture>
                    </Link>
                    <Link className={`${css.box} ml-2`} to="/redeem/refer" style={{ width: "80px" }}>
                      <picture className={`${css.moneyIcon_container}`}>
                        <img
                          src={'https://i.postimg.cc/GpKjvGx1/notification-activity-reward.png'}
                          className="snip-img"
                          alt=''
                        />
                      </picture>
                      <div className="mt-1 ml-1">
                        <div className={`${css.moneyBox_header}`}>Earning</div>
                        <div className={`${css.moneyBox_text}`}>
                          {user && user.referral_wallet.toFixed(2)}</div>
                      </div>
                    </Link>
                  </div>
                  <span className="mx-5"></span>
                </div>
                <span className="mx-5"></span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="w3-teal">
          <div className="w3-container">
            <div className={`${css.headerContainer} justify-content-between`}>
              <Link to="/">
                <picture className={`ml-2 ${css.navLogo} d-flex`}>
                  <img
                    src="https://i.postimg.cc/7YmjkDQ2/Header-profile-2.png"
                    className="snip-img logo"
                    alt='Logo'
                    onClick={handleLogoClick} // Handle click event
                  />
                </picture>
              </Link>
              <div className="ml-5">
                <Link type="button" className="login-btn text-success border-success" to="/login">SIGNUP</Link>
                <Link type="button" className="login-btn border-primary" to="/login">LOGIN</Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
