import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const Support = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          localStorage.removeItem("token");
          // history.pushState("/login")
        }
      });
  };

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  useEffect(() => {
    fetchData();
    getUser();
  }, []);

  // Updated WhatsApp link
  const whatsappLink = `https://wa.me/9672002542}?text=Hello%20admin,%20I%20need%20help%20%3F%20My%20phone%20number%20is%20 *${user?.Phone}* `;

  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="cxy flex-column " style={{ paddingTop: "16%" }}>
          <img
            src='https://i.postimg.cc/k92yVCc9/contact-us.webp'
            width="280px"
            alt=""
          />
          <div
            className="games-section-title mt-4"
            style={{ fontSize: "1.2em", fontWeight: "700", color: "2c2c2c" }}
          >
            Contact us at below platforms.
          </div>

          <div className="row">
            <div className="col-4  d-flex justify-content-around w-80">
              <a className="cxy flex-column" href={"https://t.me/LKYAAR"}>
                <img
                  width="50px"
                  src={"https://i.postimg.cc/HnLbZ5V4/telegram.png"}
                  alt=""
                />
                <span className="footer-text-bold">telegram</span>
              </a>
            </div>

            <div className="col-4  d-flex justify-content-around w-80 ">
              <a
                className="cxy flex-column"
                href={
                  WebSitesettings.instragram
                    ? "https://www.instagram.com/ludokheloyaa/direct/inbox/"
                    : "https://www.instagram.com/ludokheloyaar/"
                }
              >
                <img
                  width="50px"
                  src={"https://i.postimg.cc/Nj3T99xs/instagram.png"}
                  alt=""
                />
                <span className="footer-text-bold">instagram</span>
              </a>
            </div>

            <div className="col-4  d-flex justify-content-around w-80 ">
              <a
                className="cxy flex-column"
                href={whatsappLink}
                target="_blank"
              >
                <img
                  width="50px"
                  src={"https://i.postimg.cc/tCj8v45Z/whatsapp.png"}
                  alt=""
                />
                <span className="footer-text-bold">whatsapp</span>
              </a>
            </div>
          </div>

          <div className="col-12 my-2 text-center font-weight-bold">
            <a
              className="cxy flex-column"
              href={
                WebSitesettings.CompanyEmail
                  ? "mailto:" + WebSitesettings.CompanyEmail
                  : ""
              }
            >
              <img
                width="50px"
                src={"https://i.postimg.cc/6QV78H3c/mail.jpg"}
                alt=""
              />
              <span className="footer-text-bold">
                {WebSitesettings.CompanyEmail
                  ? WebSitesettings.CompanyEmail
                  : ""}
              </span>
            </a>
          </div>

          <div className="col-12 my-2 text-center font-weight-bold">
            <a className="cxy flex-column" href="#">
              <span className="footer-text-bold">
                <a
                  href={
                    WebSitesettings.CompanyMobile
                      ? "tel:" + WebSitesettings.CompanyMobile
                      : ""
                  }
                >
                  {WebSitesettings.CompanyMobile
                    ? WebSitesettings.CompanyMobile
                    : ""}
                </a>
              </span>
              <span className="footer-text-bold">
                {WebSitesettings ? WebSitesettings.CompanyName : ""}
              </span>
              <span className="footer-text-bold">
                {WebSitesettings ? WebSitesettings.CompanyAddress : ""}
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Support;
