import React, { useEffect, useRef, useState } from "react";
import "../css/layout.css";
import css from "../Modulecss/Home.module.css";
import axios from "axios";
import { NavLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import BetCard from "./BetCard";
import RunningCard from "./RunningCard";
import Header from "../Components/Header";

export default function Homepage({ walletUpdate }) {
  let userID = useRef();
  const isMounted = useRef(true);
  const textRef = useRef(null); // Reference for sliding text

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;

  const nodeMode = useRef(process.env.NODE_ENV);
  let baseUrl = useRef(nodeMode.current === "development" ? beckendLocalApiUrl : beckendLiveApiUrl);

  const [user, setUser] = useState();
  const [created, setCreated] = useState([]);
  const [socket, setSocket] = useState();
  const [userAllData, setUserAllData] = useState();
  const [game_type, setGame_type] = useState(useLocation().pathname.split("/")[2]);
  const [Game_Ammount, setGame_Ammount] = useState();
  const [allgame, setallgame] = useState([]);
  const [mount, setMount] = useState(false);
  const [runningGames, setRunningGames] = useState([]);
  const [ownRunning, setOwnRunning] = useState([]);

  // Function to handle API Errors
  const handleApiError = (e) => {
    console.error('API Error:', e);
    if (e.response && [401, 400, 429].includes(e.response.status)) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const res = await axios.get(baseUrl.current + `me`, { headers });
      if (isMounted.current) {
        setUser(res.data._id);
        setUserAllData(res.data);
        userID.current = res.data._id;
        setMount(true);
      }
    } catch (e) {
      handleApiError(e);
    }
  };

  const ChallengeCreate = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .post(
        baseUrl.current + `challange/create`,
        {
          Game_Ammount,
          Game_type: game_type,
        },
        { headers }
      )
      .then((res) => {
        const msg = res.data.msg;
        if (msg) {
          Swal.fire({
            title: msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (socket) {
          socket.emit("gameCreated");
        }
      })
      .catch((e) => {
        handleApiError(e);
      });
  };

  const Allgames = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl.current + `challange/all`, { headers })
      .then((res) => {
        let owenedCreated = [], remainingGame = [];
        res.data.forEach(function (ele) {
          if (ele.Created_by?._id === user && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        if (isMounted.current) {
          setCreated(owenedCreated);
          setallgame(remainingGame);
        }
      })
      .catch((e) => {
        handleApiError(e);
      });
  };

  const runningGame = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl.current + `challange/running/all`, { headers })
      .then((res) => {
        let owenedRunning = [], remainingRunning = [];
        res.data.forEach(function (ele) {
          if ((ele.Created_by?._id === userID.current || ele.Accepetd_By?._id === userID.current)) {
            owenedRunning.push(ele);
          } else {
            remainingRunning.push(ele);
          }
        });
        if (isMounted.current) {
          setOwnRunning(owenedRunning);
          setRunningGames(remainingRunning);
        }
      })
      .catch((e) => {
        handleApiError(e);
      });
  };

  function winnAmount(gameAmount) {
    let profit = null;
    if (gameAmount >= 10 && gameAmount <= 250)
      profit = gameAmount * 10 / 100;
    else if (gameAmount > 250 && gameAmount <= 500)
      profit = 25;
    else if (gameAmount > 500)
      profit = gameAmount * 5 / 100;
    return gameAmount - profit;
  }

  const deleteChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .delete(baseUrl.current + `challange/delete/${_id}`, { headers })
      .then((res) => {
        if (socket) {
          socket.emit("deleteGame", _id);
        }
      })
      .catch((e) => {
        handleApiError(e);
      });
  };

  const AcceptChallang = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .put(
        baseUrl.current + `challange/accept/${id}`,
        {
          Accepetd_By: headers,
          Acceptor_by_Creator_at: Date.now(),
        },
        { headers }
      )
      .then((res) => {
        const msg = res.data.msg;
        if (msg) {
          Swal.fire({
            title: msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (socket) {
          Allgames();
          socket.emit("acceptGame");
        }
      })
      .catch((e) => {
        handleApiError(e);
      });
  };

  const RejectGame = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl.current + `challange/reject/${id}`,
        {
          Accepetd_By: null,
          Status: "new",
          Acceptor_by_Creator_at: null,
        },
        { headers }
      )
      .then((res) => {
        if (socket) {
          socket.emit("gameRejected");
        }
      })
      .catch((e) => {
        handleApiError(e);
      });
  };

  const updateChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl.current + `challange/running/update/${_id}`,
        {
          Acceptor_seen: true,
        },
        { headers }
      )
      .then((res) => {
        if (socket) {
          socket.emit("game_seen");
        }
      })
      .catch((e) => {
        handleApiError(e);
      });
  };

  const getPost = async (Id) => {
    if (socket) {
      if (["Ludo Classics Manual", "Ludo 1 Goti", "Snakes"].includes(game_type)) {
        socket.emit('roomCodeManual', { game_id: Id, status: 'running' });
      } else if (game_type === 'Ludo Classics Auto') {
        socket.emit('roomCodeAuto', { game_id: Id, status: 'running' });
      } else if (game_type === 'Ludo Popular') {
        socket.emit('roomCodeManual', { game_id: Id, status: 'running' });
      }
    }
  }

  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };

    let socketInstance = new WebSocket("wss://socket.ludokheloyaar.com/server");

    function openFunc() {
      socketInstance.onopen = () => {
        console.log('websocket is connected 👍');
        setSocket(socketInstance);
        socketInstance.pingTimeout = setTimeout(() => {
          socketInstance.close();
          setSocket(undefined);
        }, 30000 + 1000);
      }
    }

    function listenFunc() {
      socketInstance.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          if (socketInstance._socketListeners[event]) {
            socketInstance._socketListeners[event](data);
          }
        } catch (error) {
          console.log(error);
        }
      };

      socketInstance.listen("ping", (data) => {
        socketInstance.emit("pong", 2);
        clearTimeout(socketInstance.pingTimeout);
        socketInstance.pingTimeout = setTimeout(() => {
          socketInstance.close();
          setSocket(undefined);
        }, 30000 + 1000);
      });

      socketInstance.listen("recieveGame", (data) => {
        let owenedCreated = [], remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        if (isMounted.current) {
          setCreated(owenedCreated);
          setallgame(remainingGame);
        }
      });

      socketInstance.listen("updateRunning", (data) => {
        let owenedCreated = [], remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        if (isMounted.current) {
          setCreated(owenedCreated);
          setallgame(remainingGame);
          walletUpdate();
        }
      });

      socketInstance.listen("acceptor_seen", (data) => {
        let owenedCreated = [], remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        if (isMounted.current) {
          setCreated(owenedCreated);
          setallgame(remainingGame);
        }
        let owenedRunning = [], remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current || ele.Accepetd_By?._id === userID.current) {
            owenedRunning.push(ele);
          } else {
            remainingRunning.push(ele);
          }
        });
        if (isMounted.current) {
          setOwnRunning(owenedRunning);
          setRunningGames(remainingRunning);
          walletUpdate();
        }
      });

      socketInstance.listen("resultUpdateReq", (data) => {
        let owenedRunning = [], remainingRunning = [];
        data.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current || ele.Accepetd_By?._id === userID.current) {
            owenedRunning.push(ele);
          } else {
            remainingRunning.push(ele);
          }
        });
        if (isMounted.current) {
          setOwnRunning(owenedRunning);
          setRunningGames(remainingRunning);
          walletUpdate();
        }
      });

      socketInstance.listen("startAcepptor", (data) => {
        let owenedCreated = [], remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        if (isMounted.current) {
          setCreated(owenedCreated);
          setallgame(remainingGame);
          walletUpdate();
        }
      });

      socketInstance.listen("challengeAccepted", (data) => {
        let owenedCreated = [], remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        if (isMounted.current) {
          setCreated(owenedCreated);
          setallgame(remainingGame);
        }
      });

      socketInstance.listen("updateReject", (data) => {
        let owenedCreated = [], remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        if (isMounted.current) {
          setCreated(owenedCreated);
          setallgame(remainingGame);
        }
      });

      socketInstance.listen("ongoingChallenge", (data) => {
        let owenedCreated = [], remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        if (isMounted.current) {
          setCreated(owenedCreated);
          setallgame(remainingGame);
        }
        let owenedRunning = [], remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current || ele.Accepetd_By?._id === userID.current) {
            owenedRunning.push(ele);
          } else {
            remainingRunning.push(ele);
          }
        });
        if (isMounted.current) {
          setOwnRunning(owenedRunning);
          setRunningGames(remainingRunning);
        }
      });

      socketInstance.listen("updateDelete", (data) => {
        let owenedCreated = [], remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by?._id === userID.current && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        if (isMounted.current) {
          setCreated(owenedCreated);
          setallgame(remainingGame);
        }
      });
    }

    function closeFunc() {
      socketInstance.onclose = () => {
        console.log('socket disconnected wow 😡');
        if (isMounted.current) {
          clearTimeout(socketInstance.pingTimeout);
          setSocket(undefined);
          socketInstance = new WebSocket("wss://socket.ludokheloyaar.com/server");
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }

    openFunc();
    listenFunc();
    closeFunc();

    return () => {
      isMounted.current = false;
      clearTimeout(socketInstance.pingTimeout);
      setSocket(undefined);
      socketInstance.close();
    };
  }, []);

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
    }
    role();
    if (mount) {
      Allgames();
      runningGame();
    }

    // Trigger animation on mount
    if (textRef.current) {
      textRef.current.classList.add('start-animation');
    }
  }, [mount]);

  return (
    <>
      <Header user={userAllData} />
      <div className="leftContainer" style={{ minHeight: '100vh' }}>
        <div className={css.mainArea} style={{ paddingTop: "50px", minHeight: '100vh' }}>
          {game_type === 'Ludo Classics Manual' && (
            <div className="message-container">
              <span ref={textRef} className="sliding-text start-animation">
                🙏 सभी यूजर्स से विशेष निवेदन है: 🙏

जो भी यूजर्स पॉपुलर मोड में क्लासिक मोड का रूम कोड देते हैं, उनकी आईडी हमेशा के लिए ब्लॉक कर दी जाएगी और बैलेंस भी ज़ीरो कर दिया जाएगा। कृपया ध्यान दें कि क्लासिक मोड में क्लासिक का रूम कोड दें और पॉपुलर मोड में पॉपुलर का रूम कोड। धन्यवाद!🎲
              </span>
            </div>
          )}

          <span className={`${css.cxy} ${css.battleInputHeader} mt-3 text-success`}>
            Create a Battle!
          </span>

          <div className="mx-auto d-flex my-2 w-50">
            <div>
              <input
                className={css.formControl}
                type="tel"
                placeholder="Amount"
                onChange={(e) => setGame_Ammount(e.target.value)}
              />
            </div>

            <div className="set ml-1">
              <button
                className={`bg-green ${css.playButton} cxy m-1 position-static`}
                style={{ margin: "20px !important" }}
                onClick={(e) => {
                  e.preventDefault();
                  ChallengeCreate();
                }}
              >
                Set
              </button>
            </div>
          </div>

          <div className={css.dividerX}></div>

          <div className="px-4 py-3">
            <div className="mb-3">
              <img
                src={"https://i.postimg.cc/CxJ8JhkD/battle-Icon.png"}
                alt=""
                width="20px"
              />
              <span className={`ml-2 ${css.gamesSectionTitle}`}>
                Open Battles
              </span>
              <span
                className={`${css.gamesSectionHeadline} text-uppercase position-absolute mt-2 font-weight-bold`}
                style={{ right: "1.5rem" }}
              >
                Rules
                <NavLink to="/Rules">
                  <img
                    className="ml-2"
                    src={"https://i.postimg.cc/VN6Qs0Bq/info.png"}
                    alt=""
                  />
                </NavLink>
              </span>
            </div>

            {created &&
              created.map((allgame) =>
                (allgame.Game_type === game_type) && (
                  <BetCard key={allgame._id} allgame={allgame} user={user} deleteChallenge={deleteChallenge} getPost={getPost} RejectGame={RejectGame} winnAmount={winnAmount} AcceptChallang={AcceptChallang} updateChallenge={updateChallenge} />
                )
              )}
            {allgame &&
              allgame.map((allgame) =>
                (
                  (allgame.Status === "new" ||
                    (allgame.Status === "requested" && (user === allgame.Created_by?._id || user === allgame.Accepetd_By?._id)) ||
                    (allgame.Status === "running" && user === allgame.Accepetd_By?._id && allgame.Acceptor_seen === false))
                  && allgame.Game_type === game_type
                ) && (
                  <BetCard key={allgame._id} allgame={allgame} user={user} deleteChallenge={deleteChallenge} getPost={getPost} RejectGame={RejectGame} winnAmount={winnAmount} AcceptChallang={AcceptChallang} updateChallenge={updateChallenge} />
                )
              )}
          </div>

          <div className={css.dividerX}></div>
          <div className="px-4 py-3">
            <div className="mb-2">
              <img
                src={"https://i.postimg.cc/SxG657Fn/runningbattle.jpg"}
                alt=""
                width="20px"
              />
              <span className={`ml-2 ${css.gamesSectionTitle}`}>
                Running Battles
              </span>
            </div>
            {ownRunning && ownRunning.map((runnig) => {
              if (((user === runnig.Accepetd_By?._id ? ((runnig.Status === "running" && user === runnig.Accepetd_By?._id && runnig.Acceptor_seen === true) || (runnig.Status === "pending")) : ((runnig.Status === "running" && user === runnig.Created_by?._id) || (runnig.Status === "pending"))) || runnig.Status === "conflict") && runnig.Game_type === game_type)
                return (
                  <RunningCard key={runnig._id} runnig={runnig} user={user} winnAmount={winnAmount} />
                );
            })}

            {runningGames &&
              runningGames.map((runnig) => {
                if (((user === runnig.Accepetd_By?._id || user === runnig.Created_by?._id) ? (user === runnig.Accepetd_By?._id ? ((runnig.Status === "running" && user === runnig.Accepetd_By?._id && runnig.Acceptor_seen === true) || (runnig.Status === "pending" && runnig.Acceptor_status == null)) : ((runnig.Status === "running" && user === runnig.Created_by?._id) || (runnig.Status === "pending" && runnig.Creator_Status == null))) : (runnig.Status === "running" || runnig.Status === "pending")) && runnig.Game_type === game_type)
                  return (
                    <RunningCard key={runnig._id} runnig={runnig} user={user} winnAmount={winnAmount} />
                  );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
