// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_container__FGaf8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  font-size: 1.2em;
  text-align: center;
  background: linear-gradient(135deg, rgba(5, 150, 105, 1) 0%, rgba(48, 209, 88, 1) 100%);
}

.notification_text3D__Jt\\+Bh {
  color: rgb(255, 255, 255);
  font-size: 1.2em;
  font-weight: 700;
  text-shadow: 1px 1px 0 rgb(31, 33, 36), 2px 2px 0 rgb(31, 33, 36);
}

.notification_emoji__ZP\\+iZ {
  font-size: 2em;
  margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/app2.js/css/notification.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,uFAAuF;AACzF;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,iEAAiE;AACnE;;AAEA;EACE,cAAc;EACd,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  color: white;\n  font-size: 1.2em;\n  text-align: center;\n  background: linear-gradient(135deg, rgba(5, 150, 105, 1) 0%, rgba(48, 209, 88, 1) 100%);\n}\n\n.text3D {\n  color: rgb(255, 255, 255);\n  font-size: 1.2em;\n  font-weight: 700;\n  text-shadow: 1px 1px 0 rgb(31, 33, 36), 2px 2px 0 rgb(31, 33, 36);\n}\n\n.emoji {\n  font-size: 2em;\n  margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `notification_container__FGaf8`,
	"text3D": `notification_text3D__Jt+Bh`,
	"emoji": `notification_emoji__ZP+iZ`
};
export default ___CSS_LOADER_EXPORT___;
