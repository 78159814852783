import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import ReactPaginate from "react-paginate";
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const Gamehistory = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [user, setUser] = useState();
  const [game, setGame] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  let limit = 50;

  const role = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`
      };
      const res = await axios.get(`${baseUrl}me`, { headers });
      setUser(res.data);
      Allgames(res.data._id, pageNumber, limit);
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  const handlePageClick = (data) => {
    let currentPage = data.selected;
    setPageNumber(currentPage);
  };

  const Allgames = async (id, pageNumber, limit) => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`
      };
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

      const res = await axios.get(`${baseUrl}game/history/user/${id}?page=${pageNumber}&_limit=${limit}`, { headers });
      let gameHis = [];
      res.data.data.forEach((ele) => {
        const gameDate = new Date(ele.createdAt);
        if ((ele.Status === 'completed' || ele.Status === 'cancelled' || ele.Status === 'conflict') && gameDate >= oneMonthAgo) {
          gameHis.push(ele);
        }
      });
      setGame(gameHis);
      setNumberOfPages(res.data.totalPages);
    } catch (error) {
      console.error("Error fetching game history:", error);
    }
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString('default', { month: 'short', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
    return newDate;
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    role();
  }, [pageNumber]);

  return (
    <div>
      <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
        <div className="pt-5">
          <div className="mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={numberOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
        {game && game.map((card, index) => (
          <div key={index} className={`w-100 mt-3 py-3 d-flex align-items-center ${css.list_item}`}>
            <div className={`${css.center_xy} ${css.list_date} mx-2`}>
              <div>{dateFormat(card.createdAt).split(',')[0]}</div>
              <small>{dateFormat(card.createdAt).split(',')[1]}</small>
            </div>
            <div className={`${css.list_divider_y}`} />
            <div className={`mx-3 d-flex ${css.list_body}`}>
              <div className="d-flex align-items-center">
                <picture className="mr-2">
                  <img
                    height="32px"
                    width="32px"
                    src={'https://i.postimg.cc/zXvfq25K/apple-icon-114x114.png'}
                    alt=""
                    style={{ borderRadius: "5px" }}
                  />
                </picture>
              </div>
              <div className="d-flex flex-column font-8">
                <div>
                  {card.Status === "completed" ? (card.Winner && card.Winner._id === user._id ? 'Win Against' : 'Lost Against') : card.Status} <b>{card.Accepetd_By && card.Accepetd_By._id === user._id ? card.Created_by.Name : card.Accepetd_By && card.Accepetd_By.Name}</b>.
                </div>
                <div className={`${css.games_section_headline}`}>
                  Room Code: <span style={{ color: '#007bff' }}>{card.Room_code}</span>
                </div>
              </div>
            </div>
            {card.Status === 'completed' && (
              <div style={{ marginLeft: 'auto' }} className="right-0 d-flex align-items-end pr-3 flex-column">
                <div className="d-flex float-right font-8">
                  <div className="text-danger">{card.losestatus}</div>
                  <div className="text-success">{card.winstatus}</div>
                  <picture className="ml-1 mb-1">
                    <img height="21px" width="21px" src={'https://i.postimg.cc/D0nPWJnJ/global-rupee-Icon.png'} alt="" />
                  </picture>
                  <span className="pl-1" style={{ color: card.Winner._id === user._id ? 'green' : 'red' }}>
                    {card.Status === "completed" ? (card.Winner._id === user._id ? `(+${card.winnAmount})` : `(-${card.Game_Ammount})`) : ''}
                  </span>
                </div>
                <div className={`${css.games_section_headline}`} style={{ fontSize: "0.6em", whiteSpace: 'nowrap', color: getRandomColor() }}>
                  <span style={{ color: "black" }}>Closing Balance: </span>{card.Winner._id === user._id ? card.Winner_closingbalance : card.Loser_closingbalance}
                </div>
              </div>
            )}
          </div>
        ))}
        {game && game.length === 0 &&
          <div className="text-center">
            <img
              src={'https://i.postimg.cc/sghGrfCt/nogamehistory.png'}
              alt="no data"
              width={300}
              height={300}
              className="img-fluid "
              style={{ marginTop: "25%" }}
            />
            <div className="mt-2">
              <h3 className="font-weight-bold">No Game History</h3>
              <p> You have no game history yet.</p>
            </div>
          </div>
        }
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Gamehistory;