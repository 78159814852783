import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState(null);
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const isMounted = useRef(true);

  const fetchData = async () => {
    try {
      const response = await fetch(baseUrl + "settings/data");
      const data = await response.json();
      if (isMounted.current) {
        setWebsiteSettings(data);
      }
    } catch (error) {
      console.error('Error fetching website settings:', error);
    }
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const res = await axios.get(baseUrl + `me`, { headers });
      if (isMounted.current) {
        setUserAllData(res.data);
      }
    } catch (e) {
      if (e.response && e.response.status === 401) {
        localStorage.removeItem("token");
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;
    let access_token = localStorage.getItem("token");
    if (!access_token) {
      // window.location.reload();
    }
    role();
    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const whatsappNumber = 9672002542;
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=Hello%20admin,%20I%20need%20help%20%3F%20My%20phone%20number%20is%20*${userAllData?.Phone}*`;

  // Add sliding text animation
  useEffect(() => {
    const slidingText = document.querySelector(".sliding-text");
    if (slidingText) {
      slidingText.style.animation = "slide 10s linear infinite";
    }
  }, []);

  return (
    <>
      <Header user={userAllData} />
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "80px" }}>
          <div className="referBox">
            <span className="sliding-text">
              📢 Lowest Commission Alert! 🎉 Play with just a 3% commission and get a 2% referral bonus! ✨
            </span>
          </div>

          {userAllData?.verified === "unverified" && (
            <div className="container mt-3 pl-3 pr-3">
              <div
                className="collapseCard-body"
                style={{
                  height: "64px",
                  opacity: 1,
                  transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                }}
              >
                <span className="blink text-danger float-left font-weight-bold">
                  KYC Pending !
                </span>{" "}
                &emsp; &nbsp;
                <span className="float-right">
                  <Link
                    to={userAllData?.verified === `unverified` ? `/Kyc2` : `/Profile`}
                    className="btn btn-danger text-light btn-sm"
                  >
                    COMPLETE NOW
                  </Link>
                </span>
              </div>
            </div>
          )}

          {userAllData?.verified === "pending" && (
            <div className="container mt-3 pl-3 pr-3">
              <div
                className="collapseCard-body"
                style={{
                  height: "64px",
                  opacity: 1,
                  transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                }}
              >
                <span className="blink text-danger float-left">
                  Your KYC is Under Processing !
                </span>
              </div>
            </div>
          )}

          <section className="games-section p-3">
            <div className="gameBox">
              <div>Tournaments</div>
              <div className="youtube">
                <a
                  href="https://youtu.be/rPPODIz9PRo"
                  target="_blank"
                  className="text-light"
                >
                  <picture>
                    <img
                      height="10px"
                      width="14px"
                      src="https://i.postimg.cc/Wpw7BnwM/global-yt-Play-Icon.png"
                      alt=""
                    />
                  </picture>
                  &nbsp; How to play?
                </a>
              </div>
              <div className="youtube">
                <a
                  href="https://www.youtube.com/watch?v=yc8HqnpVNVs"
                  target="_blank"
                  className="text-light"
                >
                  ADD CASH
                </a>
              </div>
              <div className="youtube">
                <a href="/" target="_blank" className="text-light">
                  KYC Video
                </a>
              </div>
            </div>

            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window">
                <Link
                  className="gameCard-container mb-2"
                  to={`/Homepage/Ludo%20Classics%20Manual`}
                >
                  <span className="blink text-success d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={`https://i.postimg.cc/26j2t2S6/first-Ludo.jpg`}
                      alt="Ludo Classics"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </picture>
                  <div className="gameCard-title">◉ LUDO CLASSICS</div>
                  <div className="goverlay">
                    <div className="text">Coming Soon</div>
                  </div>
                </Link>

                <Link
                  className="gameCard-container mb-2"
                  to={`/Homepage/Snakes`}
                >
                  <span className="blink text-success d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="60%"
                      src={`https://i.postimg.cc/9Fzg6frw/snake.jpg`}
                      alt="Snakes"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </picture>
                  <div className="gameCard-title">◉ SNAKES</div>
                  <div className="goverlay">
                    <div className="text">Coming Soon</div>
                  </div>
                </Link>

                <Link
                  className="gameCard-container mb-2"
                  to={`/Homepage/Ludo%20Popular`}
                >
                  <span className="blink text-success d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="60%"
                      src={`https://i.postimg.cc/HnNzYQVc/popular-mode.jpg`}
                      alt="Popular Mode"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </picture>
                  <div className="gameCard-title">◉ POPULAR MODE</div>
                  <div className="goverlay">
                    <div className="text">Coming Soon</div>
                  </div>
                </Link>

                <div className="gameCard-container mb-2">
                  <a href={whatsappLink} target="_blank">
                    <span className="blink text-success d-block text-right">
                      ◉ LIVE
                    </span>
                    <picture className="gameCard-image">
                      <img
                        width="60%"
                        src={`https://i.postimg.cc/vZFwY9R6/whatsapp-support.png`}
                        alt="WhatsApp Support"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </picture>
                    <div className="gameCard-title">◉ WHATSAPP SUPPORT</div>
                    <div className="goverlay">
                      <div className="text">Contact Us</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="footer">
            <div className="footer-divider" />
            <a
              className="px-3 py-4 d-flex align-items-center"
              href="#!"
              style={{ textDecoration: "none" }}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              <picture className="icon">
                <img
                  src="https://i.postimg.cc/3wkXtyRn/logo-1.jpg"
                  width="56px"
                  height="56px"
                  alt="profile"
                  style={{ width: "125px", height: "40px" }}
                />
              </picture>
              <span
                style={{
                  color: "rgb(149, 149, 149)",
                  fontSize: "1em",
                  fontWeight: 400,
                }}
                className={!open ? "d-block" : "d-none"}
              >
                {" "}
                &nbsp; &nbsp; Terms, Privacy, Support
              </span>

              {open ? (
                <i
                  className="mdi mdi-chevron-up ml-auto"
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                ></i>
              ) : (
                <i
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                  className="mdi mdi-chevron-down ml-auto"
                ></i>
              )}
            </a>
            <Collapse in={open}>
              <div id="example-collapse-text" className="px-3 overflow-hidden">
                <div className="row footer-links">
                  <Link className="col-6" to="/term-condition">
                    Terms &amp; Condition
                  </Link>
                  <Link className="col-6" to="/PrivacyPolicy">
                    Privacy Policy
                  </Link>
                  <Link className="col-6" to="/RefundPolicy">
                    Refund/Cancellation Policy
                  </Link>
                  <Link className="col-6" to="/contact-us">
                    Contact Us
                  </Link>
                  <Link className="col-6" to="/responsible-gaming">
                    Responsible Gaming
                  </Link>
                </div>
              </div>
            </Collapse>
          </section>
        </div>
      </div>
      <div className="rightContainer">
        <section className="games-section p-3">
          <div className="gameBox">
            <div>Helpdesk</div>
            <div className="youtube">
              <a
                href="https://wa.me/9672002542"
                target="_blank"
                className="text-light"
              >
                <picture>
                  <img
                    height="10px"
                    width="14px"
                    src="https://i.postimg.cc/Wpw7BnwM/global-yt-Play-Icon.png"
                    alt="WhatsApp Support"
                  />
                </picture>
                &nbsp; WhatsApp Support
              </a>
            </div>
            <div className="youtube">
              <a href="/" target="_blank" className="text-light">
                Telegram Support
              </a>
            </div>
          </div>
        </section>
        <div className="Advertisement">
          <picture className="gameCard-image">
            <img
              width="100%"
              src={`https://i.postimg.cc/HnNzYQVc/popular-mode.jpg`}
              alt="Popular Mode"
              style={{ width: "100%", height: "auto" }}
            />
          </picture>
        </div>
      </div>
      <Downloadbutton /> {/* Always show the Download button */}
    </>
  );
}
