import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import css from "../css/Refer.module.css";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const Refer = () => {
  const [user, setUser] = useState();
  const [cardData, setCardData] = useState([]);

  const fetchData = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      const res = await axios.get(`https://api.ludokheloyaar.com/me`, { headers });
      setUser(res.data);
      Allgames(res.data.referral_code);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  };

  const Allgames = async (id) => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      const res = await axios.get(`https://api.ludokheloyaar.com/referral/code/${id}`, { headers });
      setCardData(res.data);
    } catch (error) {
      console.error("Error fetching game data:", error.message);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while fetching game data!',
      });
    }
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  const copyCode = () => {
    navigator.clipboard.writeText(user.referral_code);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Referral Code Copied!',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const copyReferralLink = () => {
    const referralLink = `🎲💰 Hey there! Ready to boost your earnings? Join us at Ludokheloyaar for some serious fun! Play Ludo and stand a chance to win up to Rs10,000 every day with just a tiny 3% commission rate! Plus, score an extra 2% bonus on all games when you refer friends. With 24/7 WhatsApp support and super-fast withdrawals, the excitement never ends! Sign up now using My refer code is ${user?.referral_code}. link https://ludokheloyaar.com/login/${user?.referral_code}`;
    navigator.clipboard.writeText(referralLink);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Referral Link Copied!',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div>
      {user && (
        <Helmet>
          <title>Ludokheloyaar - Play Ludo and Win Cash Prizes</title>
          <meta name="description" content="Ludokheloyaar: Ludo with 3% commission, 2% referral earnings, and instant withdrawals. Play, win, and cash out in a heartbeat!" />
          <meta name="keywords" content="fantasy cricket, Ludokheloyaar, ludo, ludo khelo, win cash prizes" />
          <meta property="og:description" content={`Play Ludo, earn Rs10,000 daily with just a 3% commission! Get an additional 2% referral bonus on all games. Enjoy 24x7 WhatsApp support and instant withdrawals via UPI/Bank. Register now for non-stop fun and rewards! My refer code is ${user?.referral_code}. Click here: https://ludokheloyaar.com/login/${user?.referral_code}`} />
          <meta property="og:title" content="Ludokheloyaar - Play Ludo and Win Cash Prizes" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`https://ludokheloyaar.com/login/${user?.referral_code}`} />
          <meta property="og:image" content="https://ludokheloyaar.com/logo192.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Ludokheloyaar - Play Ludo and Win Cash Prizes" />
          <meta name="twitter:description" content={`Play Ludo, earn Rs10,000 daily with just a 3% commission! Get an additional 2% referral bonus on all games. Enjoy 24x7 WhatsApp support and instant withdrawals via UPI/Bank. Register now for non-stop fun and rewards! My refer code is ${user?.referral_code}. Click here: https://ludokheloyaar.com/login/${user?.referral_code}`} />
          <meta name="twitter:image" content="https://ludokheloyaar.com/logo192.png" />
        </Helmet>
      )}
      <div className={css.mainArea} style={{ paddingTop: "15px", minHeight: "100vh" }}>
        <div className="leftContainer" style={{ minHeight: "100vh", height: "100%" }}>
          <div className={`${css.center_xy} pt-5`}>
            {/* Refer & Earn Card */}
            <div className="card mt-3" style={{ border: '1px solid rgb(204, 204, 204)', width: '94%', margin: '0 auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <div className="card-header text-center" style={{ backgroundColor: 'light', color: 'black', fontWeight: 500, fontSize: '15px', letterSpacing: '0.3px' }}>Your Referral Earnings</div>
              <div className="card mb-2 p-2" style={{ borderRadius: '8px', boxShadow: '0px 0px 5px rgba(128, 128, 128)' }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-center font-9">Total Earnings: <b>{user?.referral_earning ? user.referral_earning.toFixed(2) : '0.00'}</b></div>
                  <div className="text-center font-9">Total Referrals: <b>{cardData}</b></div>
                </div>
              </div>

              <div className="p-2">
                <div className="font-14 text-center mb-2">
                  <span className="font-9" style={{ color: 'green' }}> <img src="https://i.postimg.cc/MHV5qGxn/rupee-Icon.gif" alt="" width="30px" style={{ marginLeft: '8px' }} /> Earn Now Unlimited Rewards
                    <img src="https://i.postimg.cc/MHV5qGxn/rupee-Icon.gif" alt="" width="30px" style={{ marginLeft: '8px' }} /></span>
                </div>
                <div className="d-flex justify-content-center mb-2">Share with Friends and Earn!</div>

                <div className={`${css.progress} mb-2`}>
                  <div className={`${css.progress_bar} ${css.progress_bar_striped} ${css.bg_success}`}
                    aria-valuenow={user?.referral_earning}
                    aria-valuemax={10000}
                    style={{ width: `${(user?.referral_earning * 300) / 10000}%` }}>
                  </div>
                </div>

                <div className="card mb-2 p-2" style={{ borderRadius: '8px', boxShadow: '0px 0px 5px rgba(128, 128, 128)' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-center font-9">Max Reward: ₹10,000</div>
                    <div className="d-flex justify-content-end">
                      <Link className="btn btn-sm btn-outline-danger" to="/update-pan" style={{ fontSize: '10px', borderRadius: '5px', padding: '2px 5px' }}><span className="font-9" style={{ color: 'red', fontWeight: 'bold' }}>
                        Update Limit</span></Link>
                    </div>
                  </div>
                </div>

                <div className="card mb-2 p-2" style={{ borderRadius: '8px', boxShadow: '0px 0px 5px rgba(128, 128, 128)' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="font-9">Your Current Earnings:</span>
                    <span className="font-9" style={{ border: '0.9px solid green', borderRadius: '5px', padding: '3px', margin: '1px', color: 'green' }}>
                      <img src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp" alt="" width="18px" />&nbsp;<b>{user?.referral_wallet ? user.referral_wallet.toFixed(2) : '0.00'} ₹</b>
                    </span>

                    <Link className="btn btn-sm btn-outline-primary" to="/Redeem" style={{ fontSize: '10px', borderRadius: '5px', padding: '2px 5px' }}><span className="font-9" style={{ color: 'blue', fontWeight: 'bold' }}>
                      Redeem</span></Link>
                  </div>
                </div>

                <div className="card mb-2 p-2" style={{ borderRadius: '8px', boxShadow: '0px 0px 5px rgba(128, 128, 128)' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className={`${css.text_bold} text-center`}>
                      Your Refer Code: {user?.referral_code}
                      <i className="ri-clipboard-fill ml-2" style={{ fontSize: "16px", color: "#007bff", cursor: "pointer" }} onClick={copyCode}></i>
                    </div>
                    <div className="text-center">
                      <button className="btn btn-sm btn-success" style={{ borderRadius: '5px', fontSize: '8px', padding: '1px 4px' }} onClick={copyReferralLink}><span className="font-9" style={{ color: 'white', fontWeight: 'bold' }}>
                        Copy Refer Link</span></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3" style={{ border: '1px solid rgb(204, 204, 204)', width: '90%', margin: '0 auto', borderRadius: '8px', boxShadow: '0px 0px 8px rgba(0,0,0,0.1)' }}> <div className="text-center font-9 mb-2">
                <picture className="mt-1">
                  <img alt="img" width="130px" src="https://i.postimg.cc/t4MxvjQw/refer-and-earn.gif" className="snip-img" />
                  <img alt="img" width="100px" src="https://i.postimg.cc/kGxrQbtd/Media-240219-061445.gif" className="snip-img" />
                </picture>
              </div>
              </div>

              <div className="m-3">
                <div>
                  <div className="card mt-3" style={{ border: '1px solid rgb(204, 204, 204)', width: '100%', margin: '0 auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                    <div className="card-header text-center" style={{ backgroundColor: 'light', color: 'black', fontWeight: 500, fontSize: '15px', letterSpacing: '0.3px' }}>How It Works</div>

                    <div className="d-flex align-items-center p-2 border rounded">
                      <img alt="gift" width="72px" src={process.env.PUBLIC_URL + 'Images/refer/giftbanner.png'} className="snip-img" />
                      <div className={`${css.font_9} mx-3`} style={{ width: '60%' }}>
                        <div>When a friend signs up on Ludo khelo yaar using your referral link,</div>
                        <div className={`${css.font_8} ${css.c_green} mt-2`}>
                          You earn <strong>2% Commission</strong> on their <strong>winnings</strong>.
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center p-2 border rounded">
                      <img alt="banner" width="72px" src={process.env.PUBLIC_URL + 'Images/refer/banner.png'} className="snip-img" />
                      <div className={`${css.font_9} mx-3`} style={{ width: '60%' }}>
                        <div>When your referral participates in a 10,000 Cash battle,</div>
                        <div className={`${css.font_8} ${css.c_green} mt-2`}>
                          You receive <strong><img src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp" alt="" width="18px" /> 200 Cash</strong> as a reward!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-2 p-2" style={{ borderRadius: '8px', boxShadow: '0px 0px 5px rgba(128, 128, 128)' }}>
                <div className="text-center font-9 mb-2">
                  <a href={`whatsapp://send?text=https://ludokheloyaar.com 🎲💰 *Hey there! Ready to boost your earnings?* Join us at *Ludokheloyaar* for some serious fun! Play *Ludo* and stand a chance to *win up to Rs10,000* every day with just a tiny *3% commission rate!* Plus, score an extra *2% bonus* on all games when you *refer friends.* With *24/7 WhatsApp support* and *super-fast withdrawals,* the excitement never ends! Sign up now using my referral code:  ${user?.referral_code} link *https://ludokheloyaar.com/login/${user?.referral_code}*`}
                    style={{ width: '50%' }}>
                    <button className="btn btn-sm btn-success" style={{ borderRadius: '5px', fontSize: '8px', padding: '1px 4px', color: 'white' }}><span className="font-9" style={{ color: 'white', fontWeight: 'bold' }}>
                      Share on WhatsApp</span></button>
                  </a>
                  &nbsp;
                  <a href={`https://t.me/share/url?text=🎲💰 Hey there! Ready to boost your earnings? Join us at Ludokheloyaar for some serious fun! Play Ludo and stand a chance to win up to Rs10,000 every day with just a tiny 3% commission rate! Plus, score an extra 2% bonus on all games when you refer friends. With 24/7 WhatsApp support and super-fast withdrawals, the excitement never ends! Sign up now using My refer code is ${user?.referral_code}.&url=https://ludokheloyaar.com/login/${user?.referral_code}`} style={{ width: '50%' }}>
                    <button className="btn btn-sm btn-primary" style={{ borderRadius: '5px', fontSize: '8px', padding: '1px 4px', color: 'white' }}>
                      <span className="font-9" style={{ color: 'white', fontWeight: 'bold' }}>Share on Telegram</span>
                    </button>
                  </a>
                  &nbsp;
                  <a href={`https://www.instagram.com/share/url?text=🎲💰 Hey there! Ready to boost your earnings? Join us at Ludokheloyaar for some serious fun! Play Ludo and stand a chance to win up to Rs10,000 every day with just a tiny 3% commission rate! Plus, score an extra 2% bonus on all games when you refer friends. With 24/7 WhatsApp support and super-fast withdrawals, the excitement never ends! Sign up now using My refer code is ${user?.referral_code}.&url=https://ludokheloyaar.com/login/${user?.referral_code}`} style={{ width: '50%' }} target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-sm btn-danger" style={{ borderRadius: '5px', fontSize: '8px', padding: '1px 4px', color: 'white' }}>
                      <span className="font-9" style={{ color: 'white', fontWeight: 'bold' }}>Share on Instagram</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refer;
