import React from "react";
import css from "../css/notification.module.css";
import Rightcontainer from "../Components/Rightcontainer";

const Notification = () => {
  return (
    <div className={`${css.container}`}>
      <div className={css.text3D}>
        <span className={css.emoji} role="img" aria-label="warning">⚠️</span>
        <p>
          हमारी साइट पर वर्तमान में कार्य चल रहा है और जल्दी ही सभी सेवाएँ स्वचालित हो जाएंगी। कृपया कोई भी गलत अपडेट न दें। अपडेट होने में समय लग सकता है, लेकिन कृपया चिंता न करें, आपका पेमेंट हमारे पास सुरक्षित है। धन्यवाद! 🛠️🔒
        </p>
        <p>
          Work is currently ongoing on our site and all services will be automated soon. Please do not provide any incorrect updates. Updates may take some time, but please do not worry, your payment is safe with us. Thank you! 🛠️🔒
        </p>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Notification;
