import React, { useEffect, useState, useRef } from 'react';
import '../css/viewGame1.css';
import '../css/layout.css';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../Components/Header';
import css from '../css/Pan.module.css';
import Swal from 'sweetalert2';
import '../css/Loader.css';

export default function ViewGame1(props) {
  const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  let baseUrl = nodeMode === 'development' ? beckendLocalApiUrl : beckendLiveApiUrl;

  const location = useLocation();
  const path = location.pathname.split('/')[2];

  const [Game, setGame] = useState();
  const [status, setStatus] = useState(null);
  const [fecthStatus, setFecthStatus] = useState();
  const [scrnshot, setScrnshot] = useState(null);
  const [scrnshot1, setScrnshot1] = useState('');
  const [reason, setReason] = useState(null);
  const [socket, setSocket] = useState();
  const [roomcode, setRoomcode] = useState('');
  let submitReq = useRef(false);
  const isMounted = useRef(false);
  const [submitProcess, setProcess] = useState(true);
  const [ALL, setALL] = useState();

  const [loggedInUser, setLoggedInUser] = useState(); // Renamed from 'user'
  const [userDetails, setUserDetails] = useState(); // Renamed from 'userAllData'

  // WebSocket initialization
  useEffect(() => {
    const socket = new WebSocket('wss://socket.ludokheloyaar.com/server');

    socket.onopen = () => {
      console.log('WebSocket connected');
      setSocket(socket);
    };

    socket.onmessage = function (e) {
      try {
        const { event, data } = JSON.parse(e.data);

        // If the room code is set, update the UI for the creator
        if (event === 'room_code_set') {
          if (data.creatorId === loggedInUser) {
            setRoomcode(data.roomCode); // Set the room code in the creator's UI
            Swal.fire({
              title: 'Room Code Set',
              text: `Your room code is: ${data.roomCode}`,
              icon: 'success',
            });
          }

          // If the room code is set, notify the acceptor
          if (data.acceptorId === loggedInUser) {
            Swal.fire({
              title: 'Room Code Set',
              text: `The room code has been set by the creator: ${data.roomCode}`,
              icon: 'info',
            });
          }
        }
      } catch (error) {
        console.error('Error processing WebSocket message:', error);
      }
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    return () => {
      socket.close();
    };
  }, [loggedInUser]);

  const role = async () => {
    const access_token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setLoggedInUser(res.data._id);
        setUserDetails(res.data);
        Allgames(res.data._id);
        getCode(res.data._id);
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
          history.push('/login');
        } else {
          console.error(e); // Log other errors
        }
      });
  };

  const Allgames = async (userId) => {
    const access_token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    await axios
      .get(baseUrl + `challange/${path}`, { headers })
      .then((res) => {
        if (res.data.Status === 'new' || res.data.Status === 'requested') {
          setTimeout(async () => {
            await axios
              .get(baseUrl + `challange/${path}`, { headers })
              .then((res) => {
                if (res.data.Status === 'new' || res.data.Status === 'requested') {
                  history.push(props.location.state.prevPath);
                } else {
                  setProcess(false);
                }
              })
              .catch((error) => {
                console.error(error);
                history.push(props.location.state.prevPath);
              });
          }, 10000);
        } else {
          setProcess(false);
        }
        setALL(res.data); // Set ALL games data
        setGame(res.data); // Set Game data
        if (userId === res.data.Accepetd_By._id) setFecthStatus(res.data.Acceptor_status);

        if (userId === res.data.Created_by._id) setFecthStatus(res.data.Creator_Status);
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
          history.push('/login');
        } else {
          console.error(e);
        }
      });
  };

  const getCode = async (userId) => {
    const access_token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios.get(baseUrl + `game/roomcode/get/${path}`, { headers }).then((res) => {
      Allgames(userId);
      if (res.data.Accepetd_By === userId && res.data.Room_code) {
        setGame(res.data);
      }
    });
  };

  const checkExpire = async () => {
    const access_token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios.get(baseUrl + `game/roomcode/expire/${path}`, { headers }).then((res) => {
      history.goBack();
    });
  };

  useEffect(() => {
    let access_token = localStorage.getItem('token');
    if (!access_token) {
      window.location.reload();
      history.push('/login');
    }
    role();
  }, []);

  const clearImage = (e) => {
    setScrnshot1(null);
    setScrnshot(null);
    setStatus(null);
  };

  const Result = async (e) => {
    e.preventDefault();
    if (submitReq.current === false) {
      submitReq.current = true;
      const access_token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      if (status) {
        setProcess(true);
        const formData = new FormData();
        formData.append('file', scrnshot);
        formData.append('status', status);
        if (status === 'cancelled') {
          formData.append('reason', reason);
        }
        await axios({
          method: 'post',
          url: baseUrl + `challange/result/${path}`,
          data: formData,
          headers: headers,
        })
          .then((res) => {
            socket.emit('resultAPI');
            submitReq.current = false;
            setProcess(false);
            history.push(props.location.state.prevPath);
          })
          .catch((e) => {
            if (e.response && e.response.status === 401) {
              localStorage.removeItem('token');
              window.location.reload();
              history.push('/login');
            } else {
              console.error(e);
            }
          });
      } else {
        submitReq.current = false;
        alert('please fill all fields or Re-Select result status');
      }
    }
  };

  const getPost = async () => {
    // Play voice alert first
    const alertSound = new Audio(`${process.env.PUBLIC_URL}/Images/Homepage/voicealert.mp3`);
    alertSound.play().catch((error) => {
      console.error("Error playing the sound:", error);
    });

    // SweetAlert2 confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to set the room code?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'CLASSIC,✅YES!',
      cancelButtonText: 'POPULAR,❌NO!',
    });

    if (result.isConfirmed) {
      // Proceed with the API request if the user clicked "Yes"
      const access_token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      Swal.fire({
        title: 'loading...',
        text: 'Checking Room code',
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const response = await axios.patch(baseUrl + `challange/roomcode/${path}`, { Room_code: roomcode }, { headers });
        const type = response.data.status;

        if (type === 'success') {
          setGame(response.data.game); // Update the game state with the new data

          // Emit a WebSocket event to notify the acceptor
          socket.emit('room_code_set', {
            roomCode: roomcode,
            creatorId: response.data.game.Created_by._id,
            acceptorId: response.data.game.Accepetd_By._id,
          });

          Swal.fire({
            title: 'Room Code Set',
            text: 'The room code has been successfully set.',
            icon: 'success',
          });
        }
      } catch (error) {
        Swal.close();
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while setting the room code.',
          icon: 'error',
        });
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
          history.push('/login');
        }
      }
    }
  };

  const copyCode = (e) => {
    navigator.clipboard.writeText(Game.Room_code);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Room Code Copied',
      showConfirmButton: false,
      timer: 1200,
    });
  };

  const Completionist = () => <span>You are good to go!</span>;

  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    setScrnshot(e.target.files[0]);
  };

  return (
    <>
      <Header user={userDetails} />
      {Game && (
        <div className='leftContainer'>
          <div className='main-area' style={{ paddingTop: '60px' }}>
            {!Boolean(submitProcess) && (
              <div className='battleCard-bg'>
                <div className='battleCard'>
                  <div className='players cxy pt-2'>
                    <div className='flex-column cxy'>
                      <h5>{Game.Created_by && Game.Created_by.Name}</h5>
                      {Game?.Created_by?.avatar ? (
                        <img
                          src={baseUrl + `${Game.Created_by.avatar}`}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = process.env.PUBLIC_URL + `/user.png`;
                          }}
                          width='50px'
                          height='50px'
                          alt=''
                          style={{ borderRadius: '50%' }}
                        />
                      ) : (
                        <img
                          src={process.env.PUBLIC_URL + `/user.png`}
                          width='50px'
                          height='50px'
                          alt=''
                          style={{ borderRadius: '50%' }}
                        />
                      )}
                    </div>
                    <img className='mx-3' src={process.env.PUBLIC_URL + '/Images/Homepage/versus.png'} width='23px' alt='' />
                    <div className='flex-column cxy '>
                      <h5> {Game.Accepetd_By && Game.Accepetd_By.Name}</h5>
                      {Game?.Accepetd_By?.avatar ? (
                        <img src={baseUrl + `${Game.Accepetd_By.avatar}`} width='50px' height='50px' alt='' style={{ borderRadius: '50%' }} />
                      ) : (
                        <img
                          src={process.env.PUBLIC_URL + `/user.png`}
                          width='50px'
                          height='50px'
                          alt=''
                          style={{ borderRadius: '50%' }}
                        />
                      )}
                    </div>
                  </div>
                  <div className='amount cxy mt-2'>
                    <span style={{ opacity: '0.8' }}>Playing for</span>
                    <img className='mx-1' src={process.env.PUBLIC_URL + '/Images/LandingPage_img/global-rupeeIcon.png'} width='25x' alt='' />
                    <span style={{ fontSize: '1.2em', fontWeight: 700, opacity: '0.8' }}>{Game.Game_Ammount}</span>
                  </div>
                  <div className='thin-divider-x my-3' />
                  {(Game.Room_code == null && (
                    <div className='roomCode cxy flex-column'>
                      Waiting for Room Code...
                      <h6>रूम कोड का इंतजार है।</h6>
                      <div className='lds-spinner'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )) ||
                    (Game.Room_code != 0 && (
                      <div className='roomCode cxy flex-column'>
                        <div className='text-center'>
                          <div>Room Code</div>
                          <span>{Game.Room_code}</span>
                        </div>
                        <button className='bg-green playButton position-static mt-2' onClick={(e) => copyCode(e)}>
                          Copy Code
                        </button>
                      </div>
                    )) ||
                    (Game.Room_code == 0 &&
                      Game.Created_by._id == loggedInUser &&
                      Game.Acceptor_status != 'cancelled' &&
                      Game.Creator_Status != 'cancelled' && (
                        <div className='roomCode cxy flex-column'>
                          Set Room Code
                          <h6>लूडो किंग से रूम कोड अपलोड करें</h6>
                          <input
                            type='number'
                            className='form-control mt-1 w-75'
                            style={{ backgroundColor: '#e8eeee', border: '1px solid #47a44780' }}
                            value={roomcode}
                            onChange={(e) => setRoomcode(e.target.value)}
                          />
                          <button className='bg-green playButton position-static mt-2' type='button' onClick={() => getPost()}>
                            SET ROOM CODE
                          </button>
                        </div>
                      )) ||
                    (Game.Accepetd_By._id == loggedInUser && Game.Acceptor_status != 'cancelled' && Game.Creator_Status != 'cancelled' && (
                      <div className='roomCode cxy flex-column'>
                        Waiting for Room Code
                        <h6>रूम कोड का इंतजार है।</h6>
                        <div className='lds-spinner'>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    )) ||
                    (Game.Room_code == 0 &&
                      Game.Created_by._id == loggedInUser &&
                      Game.Acceptor_status == 'cancelled' && (
                        <div className='roomCode cxy flex-column'>
                          <img src={process.env.PUBLIC_URL + `/cancel-icon.webp`} width='50px' height='50px' alt='' style={{ borderRadius: '50%' }} />
                          <h3>Opponent has cancelled</h3>
                        </div>
                      )) ||
                    (Game.Accepetd_By._id == loggedInUser && Game.Acceptor_status == 'cancelled' && (
                      <div className='roomCode cxy flex-column'>
                        <img src={process.env.PUBLIC_URL + `/cancel-icon.webp`} width='50px' height='50px' alt='' style={{ borderRadius: '50%' }} />
                        <h3>You have CANCELLED</h3>
                      </div>
                    )) ||
                    (Game.Room_code == 0 &&
                      Game.Created_by._id == loggedInUser &&
                      Game.Creator_Status == 'cancelled' && (
                        <div className='roomCode cxy flex-column'>
                          <img src={process.env.PUBLIC_URL + `/cancel-icon.webp`} width='50px' height='50px' alt='' style={{ borderRadius: '50%' }} />
                          <h3>You have CANCELLED</h3>
                        </div>
                      )) ||
                    (Game.Accepetd_By._id == loggedInUser && Game.Creator_Status == 'cancelled' && (
                      <div className='roomCode cxy flex-column'>
                        <img src={process.env.PUBLIC_URL + `/cancel-icon.webp`} width='50px' height='50px' alt='' style={{ borderRadius: '50%' }} />
                        <h3>Opponent has cancelled</h3>
                      </div>
                    ))}
                  <div className='cxy app-discription flex-column'>
                    <span style={{ opacity: '.8' }}> Play ludo game in Ludo King App</span>
                    <div className='mt-2'>
                      <a href='https://play.google.com/store/apps/details?id=com.ludo.king' target='_blank' rel='noopener noreferrer'>
                        <img className='mr-2' src={process.env.PUBLIC_URL + '/Images/google-play.jpeg'} width='128px' height='38px' alt='' />
                      </a>
                      <a href='https://itunes.apple.com/app/id993090598' target='_blank' rel='noopener noreferrer'>
                        <img src={process.env.PUBLIC_URL + '/Images/app-store.jpeg'} width='128px' height='38px' alt='' />
                      </a>
                    </div>
                  </div>
                  <div className='thin-divider-x my-3' />
                  <div className='rules'>
                    <span className='cxy mb-1'>
                      <u>Game Rules</u>
                    </span>
                    <ol className='list-group list-group-numbered'>
                      <li className='list-group-item'>Record every game while playing.</li>
                      <li className='list-group-item'>For cancellation of game, video proof is necessary.</li>
                      <li className='list-group-item'>
                        <h6 className='d-none text-danger d-block text-center'>
                          ◉ Note: Please do not upload incorrect game results, otherwise a penalty will be imposed on your wallet balance. ₹50 penalty for updating incorrect results.
                        </h6>
                      </li>
                      <li className='list-group-item'>
                        <h6 className='d-none text-danger d-block text-center'>
                       🚨 महत्वपूर्ण सूचना:
प्रिय उपयोगकर्ता, कृपया ध्यान दें कि यदि कोई भी उपयोगकर्ता पॉपुलर का रूम कोड देता है, तो उसकी आईडी स्थायी रूप से शून्य कर दी जाएगी और उसे ब्लॉक भी कर दिया जाएगा।
⚠️ कृपया ऐसा न करें, ताकि आपको किसी भी दंड का सामना न करना पड़े। आपकी आईडी 1-2 दिन बाद फिर से पहले की तरह हो जाएगी।
धन्यवाद!
                        </h6>
                      </li>
                    </ol>
                  </div>
                  <div className='match-status-border row'>
                    <div className='col-6'>Match Status</div>
                  </div>
                  <form className='result-area' onSubmit={(e) => Result(e)} encType='multipart/form-data'>
                    {fecthStatus !== null && fecthStatus !== undefined && (
                      <p>
                        You have already updated your battle result for <h6 className='d-inline-block text-uppercase'>
                          <b>{fecthStatus}</b>
                        </h6>
                      </p>
                    )}
                    {fecthStatus === null && Game.Room_code !== 0 && (
                      <>
                        <p>After completion of your game, select the status of the game and post your screenshot below.</p>
                        <div className='MuiFormGroup-root radios' role='radiogroup' aria-label='Result'>
                          <label className='MuiFormControlLabel-root hidden Mui-disabled'>
                            <span
                              className='MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary jss2 Mui-checked jss3 Mui-disabled MuiIconButton-colorSecondary Mui-disabled Mui-disabled'
                              tabIndex={-1}
                              aria-disabled='true'>
                              <span className='MuiIconButton-label'>
                                <input className='jss4 mr-2' name='battleResult' type='radio' defaultValue='select' defaultChecked style={{ transform: 'scale(1.5)' }} />
                              </span>
                            </span>
                            <span className='MuiTypography-root MuiFormControlLabel-label Mui-disabled MuiTypography-body1' style={{ marginTop: '3px' }}>
                              (Disabled option)
                            </span>
                          </label>
                          <label className='MuiFormControlLabel-root'>
                            <span className='MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root jss8' aria-disabled='false'>
                              <span className='MuiIconButton-label'>
                                <input className='jss4 mr-2' name='battleResult' type='radio' defaultValue='winn' onClick={(e) => setStatus('winn')} style={{ transform: 'scale(1.5)' }} />
                              </span>
                              <span className='MuiTouchRipple-root' />
                            </span>
                            <span className='MuiTypography-root MuiFormControlLabel-label MuiTypography-body1' style={{ marginTop: '3px' }}>
                              I Won
                            </span>
                          </label>
                          <label className='MuiFormControlLabel-root'>
                            <span className='MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary' aria-disabled='false' root='[object Object]'>
                              <span className='MuiIconButton-label'>
                                <input className='jss4 mr-2' name='battleResult' type='radio' defaultValue='lose' onClick={(e) => setStatus('lose')} style={{ transform: 'scale(1.5)' }} />
                              </span>
                              <span className='MuiTouchRipple-root' />
                            </span>
                            <span className='MuiTypography-root MuiFormControlLabel-label MuiTypography-body1' style={{ marginTop: '3px' }}>
                              I Lost
                            </span>
                          </label>
                          <label className='MuiFormControlLabel-root'>
                            <span className='MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root' aria-disabled='false'>
                              <span className='MuiIconButton-label'>
                                <input className='jss4 mr-2' name='battleResult' type='radio' defaultValue='cancelled' onClick={(e) => setStatus('cancelled')} style={{ transform: 'scale(1.5)' }} />
                              </span>
                              <span className='MuiTouchRipple-root' />
                            </span>
                            <span className='MuiTypography-root MuiFormControlLabel-label MuiTypography-body1' style={{ marginTop: '3px' }}>
                              Cancel
                            </span>
                          </label>
                        </div>
                      </>
                    )}
                    {(fecthStatus === null || fecthStatus === undefined) && Game.Room_code === 0 && (
                      <>
                        <p>After completion of your game, select the status of the game and post your screenshot below.</p>
                        <div className='MuiFormGroup-root radios' role='radiogroup' aria-label='Result'>
                          <label className='MuiFormControlLabel-root hidden Mui-disabled'>
                            <span
                              className='MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary jss2 Mui-checked jss3 Mui-disabled MuiIconButton-colorSecondary Mui-disabled Mui-disabled'
                              tabIndex={-1}
                              aria-disabled='true'>
                              <span className='MuiIconButton-label'>
                                <input className='jss4 mr-2' name='battleResult' type='radio' defaultValue='select' defaultChecked style={{ transform: 'scale(1.5)' }} />
                              </span>
                            </span>
                            <span className='MuiTypography-root MuiFormControlLabel-label Mui-disabled MuiTypography-body1' style={{ marginTop: '3px' }}>
                              (Disabled option)
                            </span>
                          </label>
                          <label className='MuiFormControlLabel-root'>
                            <span className='MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root' aria-disabled='false'>
                              <span className='MuiIconButton-label'>
                                <input className='jss4 mr-2' name='battleResult' type='radio' defaultValue='cancelled' onClick={(e) => setStatus('cancelled')} style={{ transform: 'scale(1.5)' }} />
                              </span>
                              <span className='MuiTouchRipple-root' />
                            </span>
                            <span className='MuiTypography-root MuiFormControlLabel-label MuiTypography-body1' style={{ marginTop: '3px' }}>
                              Cancel
                            </span>
                          </label>
                        </div>
                      </>
                    )}
                    {status !== null && status !== 'cancelled' && status !== 'lose' && (
                      <div className={`${css.doc_upload} mt-5`}>
                        <input type='file' onChange={handleChange} accept='image/*' required />
                        {!scrnshot && (
                          <div className='cxy flex-column position-absolute '>
                            <i className='fa-solid fa-arrow-up'></i>
                            <div className={`${css.sideNav_text} mt-2 `}>Upload screenshot.</div>
                          </div>
                        )}
                        {scrnshot && (
                          <div className={css.uploaded}>
                            <img src='/images/file-icon.png' width='26px' alt='' style={{ marginRight: '20px' }} />
                            <div className='d-flex flex-column' style={{ width: '80%' }}>
                              <div className={`${css.name} `}>{scrnshot.name}</div>
                              <div className={css.size}>{(scrnshot.size / 1024 / 1024).toFixed(2)} MB</div>
                            </div>
                            <div className='image-block'>
                              <img src='/images/global-cross.png' width='10px' alt='' onClick={clearImage} />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {status !== null && status === 'cancelled' && (
                      <div className='form-group'>
                        <textarea
                          className='form-control border-secondary'
                          name=''
                          id=''
                          rows='3'
                          onChange={(e) => {
                            setReason(e.target.value);
                          }}
                          required></textarea>
                      </div>
                    )}
                    <div style={{ width: '100%' }}>
                      <img src={scrnshot1} style={{ width: '100%' }} />
                    </div>
                    {fecthStatus == null && fecthStatus == undefined && <input type='submit' className='btn btn-danger mt-3 text-white' id='post' />}
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {Boolean(submitProcess) && (
        <div className='loaderReturn' style={{ zIndex: '99', minHeight: '100vh' }}>
          <img src={process.env.PUBLIC_URL + '/Images/LandingPage_img/loader1.gif'} style={{ width: '100%' }} />
        </div>
      )}
      <div className='divider-y'></div>
    </>
  );
}
