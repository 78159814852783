import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import axios from "axios";

const Transactionhistory = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [user, setUser] = useState();
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [showDeposits, setShowDeposits] = useState(true);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    await axios.get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
        fetchTransactions(res.data._id);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
    return newDate;
  };

  const fetchTransactions = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    await axios.get(baseUrl + `temp/deposite/${id}`, { headers })
      .then((res) => {
        const deposits = res.data.ress.filter(card => (card.Req_type === 'deposit' || card.Req_type === 'bonus') && new Date(card.createdAt) >= oneMonthAgo);
        const withdrawals = res.data.ress.filter(card => card.Req_type === 'withdraw' && new Date(card.createdAt) >= oneMonthAgo);
        setDeposits(deposits);
        setWithdrawals(withdrawals);
      });
  };

  const checkDeposit = (order_id, order_token, pay_date, gateway) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    let url = '';
    if (gateway === 'razorpay') {
      url = baseUrl + `razorpaycheck/response`;
    } else if (gateway === 'upigateway') {
      url = baseUrl + `depositupipay/response`;
    } else if (gateway === 'Phonepe') {
      url = baseUrl + `phonepe-callback`;
    }
    axios.post(url, { order_id, order_token, pay_date }, { headers })
      .then((res) => {
        role();
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
        }
      });
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    role();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
        <div className="pt-5">
          <div className="text-center mb-4" style={{ marginTop: '20px' }}>
            <button className="btn btn-danger mx-2" onClick={() => setShowDeposits(true)} style={{ backgroundColor: 'red' }}>Deposits</button>
            <button className="btn btn-success mx-2" onClick={() => setShowDeposits(false)} style={{ backgroundColor: 'green' }}>Withdrawals</button>
          </div>
        </div>

        {showDeposits && (
          <div className="transaction-section">
            <h3>Deposits</h3>
            {deposits.length === 0 && <p>No deposits yet.</p>}
            {deposits.map((card, index) => {
              var id = card._id.toString(), ctr = 0;
              var timestamp = id.slice(ctr, (ctr += 8));
              var counterid = parseInt(timestamp, 16);

              var titleMsg = <div className="text-success">Cash added</div>;
              var signIcon = card.status === 'Pending' && card.Req_type === "deposit"
                ? <div className="text-danger">(X)</div>
                : <div className="text-success">(+)</div>;

              return (
                <div key={index}
                  className={`w-100 mt-3 py-3 d-flex align-items-center ${css.list_item}`}
                >
                  <div className={`${css.center_xy} ${css.list_date} mx-2`}>
                    <div>{dateFormat(card.createdAt).split(',')[0]}</div>
                    <small>{dateFormat(card.createdAt).split(',')[1]}</small>
                  </div>
                  <div className={`${css.list_divider_y}`} />
                  <div className={`mx-3 d-flex ${css.list_body}`}>
                    <div className="d-flex align-items-center">
                      <picture className="mr-2">
                        <img
                          height="32px"
                          width="32px"
                          src={'https://i.postimg.cc/zXvfq25K/apple-icon-114x114.png'}
                          alt=""
                          style={{ borderRadius: "5px" }}
                        />
                      </picture>
                    </div>

                    <div className="d-flex flex-column font-8">
                      <div>
                        <b>{titleMsg}</b>
                        <h6>OrderID: JP-{counterid}</h6>
                      </div>
                      <div className={`${css.games_section_headline}`}>
                        Status: <span style={{ color: getRandomColor() }}>{card.status === 'none' || (card.status === 'Pending' && card.Req_type === "withdraw") ? 'Processing' : (card.status === 'Pending' && card.Req_type === "deposit") ? card.status : card.status}</span>
                        <br></br>
                        {card.txn_msg ? card.txn_msg : ''}
                      </div>
                    </div>
                  </div>

                  <div className="right-0 d-flex align-items-end pr-3 flex-column">
                    {card.status === 'Pending' && card.Req_type === "deposit" &&
                      <button className="btn btn-danger" onClick={() => checkDeposit(card.order_id, card.order_token, card.createdAt, card.payment_gatway)} >
                        Check Status {card.payment_gatway}
                      </button>
                    }

                    <div className="d-flex float-right font-8">
                      {signIcon}
                      <picture className="ml-1 mb-1">
                        <img
                          alt="img"
                          height="21px"
                          width="21px"
                          src={'https://i.postimg.cc/D0nPWJnJ/global-rupee-Icon.png'}
                          className="snip-img"
                        />
                      </picture>
                      <span className="pl-1">{card.amount}</span>
                    </div>
                    {card.closing_balance &&
                      <div
                        className={`${css.games_section_headline}`}
                        style={{ fontSize: "0.6em", whiteSpace: 'nowrap' }}
                      >
                        <span style={{ color: "black" }}>Closing Balance: </span>
                        <span style={{ color: getRandomColor() }}>{card.closing_balance}</span>
                      </div>}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!showDeposits && (
          <div className="transaction-section">
            <h3>Withdrawals</h3>
            {withdrawals.length === 0 && <p>No withdrawals yet.</p>}
            {withdrawals.map((card, index) => {
              var id = card._id.toString(), ctr = 0;
              var timestamp = id.slice(ctr, (ctr += 8));
              var counterid = parseInt(timestamp, 16);

              var titleMsg = `Withdraw Using ${card.Withdraw_type}`;
              var signIcon = <div className="text-danger">(-)</div>;

              return (
                <div key={index}
                  className={`w-100 mt-3 py-3 d-flex align-items-center ${css.list_item}`}
                >
                  <div className={`${css.center_xy} ${css.list_date} mx-2`}>
                    <div>{dateFormat(card.createdAt).split(',')[0]}</div>
                    <small>{dateFormat(card.createdAt).split(',')[1]}</small>
                  </div>
                  <div className={`${css.list_divider_y}`} />
                  <div className={`mx-3 d-flex ${css.list_body}`}>
                    <div className="d-flex align-items-center">
                      <picture className="mr-2">
                        <img
                          height="32px"
                          width="32px"
                          src={'https://i.postimg.cc/zXvfq25K/apple-icon-114x114.pngg'}
                          alt=""
                          style={{ borderRadius: "5px" }}
                        />
                      </picture>
                    </div>

                    <div className="d-flex flex-column font-8">
                      <div>
                        <b>{titleMsg}</b>
                        <h6>OrderID: JP-{counterid}</h6>
                      </div>
                      <div className={`${css.games_section_headline}`}>
                        Status: <span style={{ color: getRandomColor() }}>{card.status === 'none' || (card.status === 'Pending' && card.Req_type === "withdraw") ? 'Processing' : (card.status === 'Pending' && card.Req_type === "deposit") ? card.status : card.status}</span>
                        <br></br>
                        {card.txn_msg ? card.txn_msg : ''}
                      </div>
                    </div>
                  </div>

                  <div className="right-0 d-flex align-items-end pr-3 flex-column">
                    <div className="d-flex float-right font-8">
                      {signIcon}
                      <picture className="ml-1 mb-1">
                        <img
                          alt="img"
                          height="21px"
                          width="21px"
                          src={'https://i.postimg.cc/D0nPWJnJ/global-rupee-Icon.png'}
                          className="snip-img"
                        />
                      </picture>
                      <span className="pl-1">{card.amount}</span>
                    </div>
                    {card.closing_balance &&
                      <div
                        className={`${css.games_section_headline}`}
                        style={{ fontSize: "0.6em", whiteSpace: 'nowrap' }}
                      >
                        <span style={{ color: "black" }}>Closing Balance: </span>
                        <span style={{ color: getRandomColor() }}>{card.closing_balance}</span>
                      </div>}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Transactionhistory;
