import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Components/Header";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/kyc.css";
import css from '../css/Pan.module.css';
import '../css/Loader.css';

const Kyc2 = ({ user }) => {
  const history = useHistory();
  const [frontLoaded, setFrontLoaded] = useState(null);
  const [backLoaded, setBackLoaded] = useState(null);
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Number, setNumber] = useState('');
  const [DOB, setDob] = useState('');
  const [process, setProcess] = useState(false);
  const aadharProcess = useRef(false);

  const baseUrl = 'https://api.ludokheloyaar.com/';
  const publicUrl = 'https://ludokheloyaar.com/';

  const handleSubmitData = async (e) => {
    e.preventDefault();
    if (!frontLoaded || !backLoaded) {
      Swal.fire("Error", "Please upload both front and back photos of your Aadhar card.", "error");
      return;
    }

    if (user.verified === "unverified") {
      if (!aadharProcess.current) {
        setProcess(true);
        aadharProcess.current = true;

        const formData = new FormData();
        formData.append("Name", Name);
        formData.append("Email", Email);
        formData.append("Number", Number);
        formData.append("DOB", DOB);
        formData.append("front", frontLoaded);
        formData.append("back", backLoaded);

        try {
          const access_token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${access_token}` };
          const res = await axios.post(`${baseUrl}aadharcard`, formData, { headers });

          if (res.data.msg === false) {
            Swal.fire("Duplicate Entity", "This Aadhar number is already registered.", "error");
          } else {
            Swal.fire("Success", "Your KYC form has been submitted successfully.", "success");
            history.push("/Profile");
          }
        } catch (e) {
          console.error(e);
          Swal.fire("Error", "There was a problem submitting your KYC form. Please try again.", "error");
          if (e.response?.status === 401) {
            localStorage.removeItem('token');
            window.location.reload();
            history.push("/login");
          }
        } finally {
          aadharProcess.current = false;
          setProcess(false);
        }
      } else {
        Swal.fire("Notice", "You have already submitted a request.", "info");
      }
    } else {
      Swal.fire("Notice", "Your request is in process.", "info");
    }
  };

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    if (!access_token) {
      window.location.reload();
      history.push("/login");
    }

    const frontPhoto = document.getElementById('frontPhoto');
    frontPhoto.onchange = e => {
      const [file] = frontPhoto.files;
      setFrontLoaded(file);
    };

    const backPhoto = document.getElementById('backPhoto');
    backPhoto.onchange = e => {
      const [file] = backPhoto.files;
      setBackLoaded(file);
    };
  }, [history]);

  return (
    <div>
      <Header />
      <div className="leftContainer">
        <div className="kycPage mt-5 py-4 px-3">
          <p className="mt-2" style={{ color: "rgb(149, 149, 149)" }}>
            You need to submit a document that shows you are{" "}
            <span style={{ fontWeight: 500 }}>above 18 years</span> of age and not a
            resident of{" "}
            <span style={{ fontWeight: 500 }}>
              Assam, Odisha, Sikkim, Nagaland, Telangana, Andhra Pradesh, Tamil Nadu, and
              Karnataka.
            </span>
          </p>
          <p className="mt-2" style={{ color: "rgb(149, 149, 149)" }}>
            Enter details of Aadhar Card:
          </p>
          <form onSubmit={handleSubmitData}>
            <div style={{ marginTop: "10px" }}>
              <div className="kyc-doc-input">
                <div className="label">First Name</div>
                <input type="text"
                  name="Name"
                  placeholder='Enter name'
                  value={Name}
                  onChange={(e) => setName(e.target.value)} required
                  className="inputField"
                />
              </div>
              <div className="kyc-doc-input mt-4">
                <div className="label">Email Id</div>
                <input type="email"
                  name="Email"
                  placeholder='Email Id'
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)} required
                  className="inputField"
                />
              </div>
              <div className="kyc-doc-input mt-4">
                <div className="label">Date of Birth</div>
                <input type="date"
                  name="DOB"
                  placeholder='Enter date of birth'
                  value={DOB}
                  onChange={(e) => setDob(e.target.value)} required
                  className="inputField"
                />
              </div>
              <div className="kyc-doc-input mt-4">
                <div className="label">Aadhar Number</div>
                <input type="tel"
                  name="Number"
                  placeholder='Aadhar Number'
                  value={Number}
                  onChange={(e) => setNumber(e.target.value)} required
                  className="inputField"
                />
              </div>
              <div className={`${css.doc_upload} mt-3`}>
                <input id="frontPhoto" name="frontPhoto" type="file" accept="image/*" required />
                {!frontLoaded && <div className="cxy flex-column position-absolute ">
                  <img src="/images/file-uploader-icon.png" width="17px" alt="" className="snip-img" />
                  <div className={`${css.sideNav_text} mt-2`}>
                    Upload front Photo of your Aadhar Card.
                  </div>
                </div>}
                {frontLoaded && <div className={css.uploaded}>
                  <img src="/images/file-icon.png" width="26px" alt="" style={{ marginRight: '20px' }} />
                  <div className="d-flex flex-column" style={{ width: '80%' }}>
                    <div className={css.name}>{frontLoaded.name}</div>
                    <div className={css.size}>{(frontLoaded.size / 1024 / 1024).toFixed(3)} MB</div>
                  </div>
                  <div className="image-block">
                    <img src="/images/global-cross.png" width="10px" alt="" onClick={() => setFrontLoaded(null)} />
                  </div>
                </div>}
              </div>
              <div className={`${css.doc_upload} mt-3`}>
                <input id="backPhoto" name="backPhoto" type="file" accept="image/*" required />
                {!backLoaded && <div className="cxy flex-column position-absolute ">
                  <img src="/images/file-uploader-icon.png" width="17px" alt="" className="snip-img" />
                  <div className={`${css.sideNav_text} mt-2`}>
                    Upload back Photo of your Aadhar Card.
                  </div>
                </div>}
                {backLoaded && <div className={css.uploaded}>
                  <img src="/images/file-icon.png" width="26px" alt="" style={{ marginRight: '20px' }} />
                  <div className="d-flex flex-column" style={{ width: '80%' }}>
                    <div className={css.name}>{backLoaded.name}</div>
                    <div className={css.size}>{(backLoaded.size / 1024 / 1024).toFixed(3)} MB</div>
                  </div>
                  <div className="image-block">
                    <img src="/images/global-cross.png" width="10px" alt="" onClick={() => setBackLoaded(null)} />
                  </div>
                </div>}
              </div>
            </div>
            <div style={{ paddingBottom: "25%" }} />
            <div className="refer-footer p-0">
              <button type="submit" className="w-100 btn-success bg-success" style={{
                border: 'none', borderRadius: '5px',
                fontSize: '1em',
                fontWeight: '700',
                height: '48px',
                color: '#fff',
                textTransform: 'uppercase',
              }}>
                Submit
              </button>
            </div>
          </form>
        </div>
        {process &&
          <div className="loaderReturn" style={{ zIndex: '99' }}>
            <img
              src={`${publicUrl}/Images/LandingPage_img/loader1.gif`}
              style={{ width: "100%", }}
              alt="Loading..."
            />
          </div>
        }
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Kyc2;
